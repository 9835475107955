<template>
  <div>
    <div
      style="background-color: #eeeeee"
      class="pb-5"
    >
      <div class="container">
        <div class="row pt-5 pb-5">
          <div class="col-md-2"></div>
          <div class="home_welcome_message col-md-8 text-center">
            <h2>
              <span id="h22">OUR WEEKLY PACKAGES</span>
            </h2>
            <p style="font-weight: 500; color: grey">
              Learn about our tailor made packages
            </p>
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="welcome_grid">
          <div
            class="welcome_flex"
            v-for="message in welcome_messages"
            :key="message.id"
          >
            <div class="welcome_image">
              <img :src="message.photo" />
            </div>
            <div class="welcome_description">
              <div>
                <small class="heading">{{ message.title }}</small><br />
                <!-- <small class="wel_text">{{ message.description }}</small> -->
              </div>
              <div>
                <ul>
                  <li>
                    <small><i class="el-icon-location-outline"></i>
                      {{ message.country }}</small>
                  </li>
                  <li>
                    <small><i class="el-icon-check"></i> Family Freindly</small>
                  </li>
                  <li>
                    <small><i class="fa-solid fa-plane"></i> Entebbe -
                      Kaabong</small>
                  </li>
                  <li>
                    <small><i class="el-icon-timer"></i> 6 Days</small>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <span style="color: #3d290a; font-weight: 600">${{ message.price }}</span>
              <small style="color: grey; padding-left: 20px">
                Avg/per person.</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    accommodationDetail(roomId) {
      this.$router.push({ path: `accommodation-detail/${roomId}` });
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0px;
}
ul li {
  color: grey;
}

h2 {
  font-weight: 600;
}

.home_welcome_message > h2 #h21 {
  font-size: 0.6em;
}

h2 #h22 {
  font-size: 0.5em;
  color: #68442f;
}
.welcome_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
}

.welcome_grid > div {
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
}

.welcome_grid > div:hover {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  transition: 0.5s;
}

.welcome_flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.welcome_flex > div:nth-child(3) {
  border-radius: 0px 0px 5px 5px;
}

.welcome_grid .welcome_image {
  height: 250px;
  border-radius: 5px;
}
.welcome_grid .welcome_image img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.welcome_grid .welcome_description {
  padding-top: 20px;
  /* padding-bottom: 0px; */
  background-color: white;
  /* border-radius: 5px; */
}

.welcome_grid .welcome_description .heading {
  font-weight: 600;
}
.welcome_grid .welcome_description .wel_text {
  color: grey;
}

.destination_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.destination_grid > div {
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;

  position: relative;
}

.destination_grid > div:hover {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  transition: 0.5s;
}

.destination_image img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.destination_text {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
}

.experience_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.experience_grid > div {
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
}

.experience_grid > div:hover {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  transition: 0.5s;
}

.experience_flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.experience_flex > div:nth-child(3) {
  border-radius: 0px 0px 5px 5px;
}

.experience_grid .experience_image {
  height: 250px;
  border-radius: 5px;
}
.experience_grid .experience_image img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
}

.experience_grid .experience_description {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  /* border-radius: 5px; */
}

.experience_grid .experience_description .heading {
  font-weight: 600;
}
.experience_grid .experience_description .wel_text {
  color: grey;
}

.cottage_welcome h2 {
  font-weight: 600;
}

.cottages_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  background-color: #fef9f6;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.cottages_grid > div {
  background-color: #fef9f6;
  border-radius: 5px;
}

.cottages_grid .welcome_image {
  height: 400px;
  border-radius: 5px;
}
.cottages_grid .welcome_image img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.cottages_grid .welcome_description {
  text-align: left;
  padding: 100px 30px;
}

.btn-brown {
  background-color: #d96b12;
  border-radius: 0px 0px 5px 5px;
  color: white;
  width: 100%;
}

.btn-view-more {
  background-color: white;
  border: 1px solid #d96b12;
  width: 250px;
}

.rooms {
  background-color: #eeeeee;
}

.rooms_grid {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
  border-radius: 5px;
}

.rooms_grid > div {
  text-align: left;
  padding: 20px;
  border-radius: 5px;
}

.rooms_grid > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.rooms_grid .rooms_image img {
  height: 260px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.rooms_grid ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .welcome_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .welcome_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .destination_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .experience_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .home_welcome_message {
    margin-top: 80px;
  }
  .home_welcome_message > h2 #h21 {
    font-size: 0.6em;
  }

  h2 #h22 {
    font-size: 0.8em;
    color: #d96b12;
  }
  .welcome_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }

  .destination_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .experience_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .home_welcome_message {
    margin-top: 80px;
  }
  h2 #h21 {
    font-size: 0.6em;
  }

  h2 #h22 {
    font-size: 0.8em;
    color: #d96b12;
  }

  .destination_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .experience_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .welcome_grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .welcome_grid .welcome_image {
    height: 150px;
    border-radius: 5px;
  }
  .welcome_grid .welcome_image img {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .welcome_grid .welcome_description {
    padding-top: 20px;
    background-color: white;
    border-radius: 5px;
  }

  .welcome_grid .welcome_description .heading {
    font-size: 0.7em;
  }
  .welcome_grid .welcome_description .wel_text {
    font-size: 0.8em;
  }

  .cottage_welcome h2 {
    font-size: 1.3em;
  }

  .cottage_welcome p {
    font-size: 0.85em;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .cottages_grid .welcome_image {
    height: 250px;
    border-radius: 5px;
  }
  .cottages_grid .welcome_image img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .cottages_grid .welcome_description {
    text-align: center;
    padding: 50px 10px;
    background-color: white;
    border-radius: 5px;
  }

  .cottages_grid .welcome_description .heading {
    font-size: 0.7em;
  }
  .cottages_grid .welcome_description .wel_text {
    font-size: 0.8em;
  }

  .rooms h2 {
    font-size: 1.2em;
  }
  .rooms p {
    font-size: 0.8em;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }

  .btn-brown {
    background-color: #d96b12;
    color: white;
    width: 100%;
    font-size: 0.7em;
  }
}
</style>