<template>
  <div>
    <section>
      <article>
        <HomeComponent></HomeComponent>
      </article>
    </section>
  </div>
</template>

<script>
import HomeComponent from "../components/home/home-component.vue";

export default {
  components: {
    HomeComponent,
  },

  data() {
    return {};
  },

  methods: {
    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: "Lato", sans-serif;
}
</style>
